import React from "react";
import styled from "styled-components";
import { ImageLeftWrapper, ImageRightWrapper } from "../../../components/images/images";
import Video from "../../../components/media/video";
import { PimcoreImage } from "../../../components/pimcore-image";
import { Headline3, Paragraph } from "../../../components/typography";
import ExampleHeaderSection from "../../../components/work/example-header-section";
import { PageElement } from "../../../graphql/page-common";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { theme } from "../../../styles/styles";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";

interface ExamplesSectionProps {
  headline: string;
  content: Array<PageElement>;
}

const ExamplesSection: React.FC<ExamplesSectionProps> = ({ headline, content }) => {
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl1.current = onScrollAnimationDesktop(firstWrapper.current, [firstWrapper.current?.children || null], matchMobile);
    tl2.current = onScrollAnimationDesktop(
      secondWrapper.current,
      [secondWrapper.current?.children || null],
      matchMobile
    );

    return () => {
      tl1?.current?.kill();
      tl2?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <ScrollWrapper>
      <WrapperExample>
        <ExampleHeaderSection
          headline={headline}
          subHeadline={getElementText(content[0])}
          primaryImage={getElementImage(content[1])}
          content={getElementText(content[2])}
          secondaryImage={getElementImage(content[3])}
          prParagraph="10"
        />
      </WrapperExample>
      <Grid ref={firstWrapper}>
        <MediaCell6>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
        </MediaCell6>
        <MediaCell7>
          <PimcoreImage image={getElementImage(content[5])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell7>
        <MediaCell8>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
        </MediaCell8>
        <MediaCell9>
          <PimcoreImage image={getElementImage(content[7])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell9>
        <MediaCell10>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[8]) }} />
        </MediaCell10>
      </Grid>
      <Grid ref={secondWrapper}>
        <MediaCell11>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
        </MediaCell11>
        <MediaCell12>
          <ImageLeftWrapper>
            <ImgMarginWrapper>
              <Video placeholder={getElementImage(content[10])} video={getElementVideo(content[10])} />
            </ImgMarginWrapper>
          </ImageLeftWrapper>
        </MediaCell12>
        <MediaCell13>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[11]) }} />
        </MediaCell13>
        <MediaCell14>
          <ImageRightWrapper>
            <PimcoreImage image={getElementImage(content[12])} modifier="w-full rounded-lg" withAspectRatio />
          </ImageRightWrapper>
        </MediaCell14>
      </Grid>
    </ScrollWrapper>
  );
};

export default ExamplesSection;
const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 px-5 mb-14 tablet:mb-36",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(5, auto);
  }
`;
const ScrollWrapper = styled.div.attrs({ className: "scrollspy" })``;
const ImgMarginWrapper = styled.div.attrs({ className: "mb-8 tablet:mb-0" })``;
const WrapperExample = styled.div.attrs({ className: "mt-[4.5rem]" })``;
const MediaCell6 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 tablet:pr-8",
})``;
const MediaCell7 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-5 tablet:row-start-1 tablet:row-span-4 mb-8 tablet:mb-0",
})``;
const MediaCell8 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 mb-4 tablet:mb-0 tablet:pr-12",
})``;
const MediaCell9 = styled.div.attrs({
  className: "hidden tablet:block tablet:col-start-2 tablet:col-span-5 tablet:row-start-4 tablet:row-span-3",
})``;
const MediaCell10 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-6 tablet:row-span-1 tablet:pr-12",
})``;
const MediaCell11 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1",
})``;
const MediaCell12 = styled.div.attrs({
  className: "tablet:col-start-1 tablet:col-span-7 tablet:row-start-1 tablet:row-span-4",
})``;
const MediaCell13 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 tablet:pr-6 tablet:pb-12",
})``;
const MediaCell14 = styled.div.attrs({
  className: "hidden tablet:block tablet:col-start-7 tablet:col-span-5 tablet:row-start-4 tablet:row-span-2",
})``;
