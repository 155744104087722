import React from "react";
import styled from "styled-components";
import QuoteElement from "../../../components/quote-element/quote-element";
import { Headline3, Paragraph } from "../../../components/typography";
import SimpleSection from "../../../components/work/simple-section";
import WorkingTogetherSection from "../../../components/work/working-together-section";
import { theme } from "../../../styles/styles";
import WorkVideoElement from "../work-video-element";
import { PageElement } from "../../../graphql/page-common";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../../components/pimcore-image";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";

interface WorkingSectionProps {
  headline: string;
  intro: Array<PageElement>;
  content: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ headline, intro, content }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <>
      <VideoWrapper>
        <WorkVideoElement
          video={getElementVideo(intro[0])}
          image={getElementImage(intro[0])}
          content={getElementText(intro[1])}
        />
      </VideoWrapper>
      <SectionWrapper>
        <WorkingTogetherSection
          headline={headline}
          subHeadline={getElementText(content[0])}
          image={getElementImage(content[1])}
          content={getElementText(content[2])}
          quote={{
            message:
              "As digital designers, we need to understand brand and customer. Data informs our design decisions by helping us to see what works best and why.",
            name: "Terri",
            position: "Head of Digital UX/UI",
            modifier: "left-4 bottom-4 tablet:left-[19rem] tablet:bottom-[27rem]",
            left: true,
          }}
          marginBottom="tablet:mb-4"
          padding="tablet:pr-14"
        />
        <Grid ref={wrapper}>
          <MediaCell1>
            <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
          </MediaCell1>
          <MediaCell2>
            <QuoteElement
              message="We call on industry best practice, data and our expertise to define, shape and optimise a smooth Miele customer experience."
              name="Kapil"
              position="Senior Product Manager"
              modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-[23rem] tablet:right-96"
            />
            <PimcoreImage image={getElementImage(content[4])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell2>
          <MediaCell3>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
          </MediaCell3>
          <MediaCell4>
            <ParagraphWrapper>
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
            </ParagraphWrapper>
          </MediaCell4>
          <MediaCell5>
            <PimcoreImage image={getElementImage(content[7])} modifier="w-full rounded-lg" withAspectRatio />
          </MediaCell5>
        </Grid>
        <SimpleSection
          headline={getElementText(content[8])}
          image={getElementImage(content[9])}
          content={getElementText(content[10])}
          quote={{
            message:
              "We use best-in-class content to articulate the premium nature of our products on key platforms, so customers know Miele is simply the best.",
            name: "Geiske",
            position: "Program Manager Channel Management",
            modifier: "left-4 bottom-4 tablet:bottom-[25rem] tablet:left-96",
            left: true,
          }}
          padding="tablet:pr-14 tablet:mr-1"
        />
        <SimpleSection
          headline={getElementText(content[11])}
          image={getElementImage(content[12])}
          content={getElementText(content[13])}
          quote={{
            message: "A comprehensive landscape of digital channels contribute to Miele’s performance globally. We’re helping local teams to maximize the performance of those channels.",
            name: "Freek",
            position: "Performance Marketing Manager",
            modifier: "left-4 bottom-4 tablet:left-auto tablet:bottom-72 tablet:right-[32rem]",
          }}
          padding="tablet:pr-12"
          left
          marginTop="tablet:mt-0"
        />
      </SectionWrapper>
    </>
  );
};

export default WorkingSection;

const VideoWrapper = styled.div.attrs({ className: " scrollspy -mt-64 tablet:-mt-72 relative z-20" })``;
const SectionWrapper = styled.div.attrs({ className: " scrollspy" })``;
const Grid = styled.div.attrs({
  className: "tablet:grid gap-4 px-5 tablet:grid-cols-12 mb-8 tablet:mb-32",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(7, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs({
  className: "tablet:pr-14",
})``;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1",
})``;
const MediaCell2 = styled.div.attrs({
  className:
    "tablet:col-start-2 tablet:col-span-5 tablet:row-start-1 tablet:row-span-5 tablet:pt-10 mb-8 tablet:mb-0 relative",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-2 tablet:pr-16",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-6 tablet:row-span-2 pt-6 tablet:pt-20 pb-6",
})``;
const MediaCell5 = styled.div.attrs({
  className: "hidden tablet:block tablet:col-start-7 tablet:col-span-5 tablet:row-start-5 tablet:row-span-3",
})``;
